<template>
  <div class="wrapper">
    <div class="row between-xs">
      <div class="col-xs">
        <h2>Segment Search</h2>
      </div>
      <div class="col-xs-1 close" @click="$emit('close')">
        <font-awesome-icon
          :icon="['fal', 'times']"
          size="2x"
        ></font-awesome-icon>
      </div>
    </div>
    <div class="row between-xs middle-xs">
      <div class="col-xs-5">
        <div class="row">
          <div class="col-xs">
            <label for=""
              >Search Term:
              <input type="text" v-debounce:300ms.fireonempty="debounceInput" />
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs">
            <v-button
              :outline="true"
              :size="2"
              :event="prev"
              :disabled="offset === 0"
            >
              Prev {{ size }} Records
              <i class="fal fa-arrow-right"></i>
            </v-button>
          </div>
          <div class="col-xs">
            <v-button :outline="true" :size="2" :event="next">
              Next {{ size }} Records
            </v-button>
          </div>
        </div>
      </div>
      <div class="col-xs-5">
        <div class="row">
          <div class="col-xs">
            <label for=""
              >Filter By Provider:
              <v-select
                :options="providers"
                label="name"
                multiple
                v-model="selected_providers"
              ></v-select>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col-xs">
            <label for="">
              Number of records:
              <v-select
                :options="[50, 100, 200, 500]"
                v-model="size"
              ></v-select>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs">
        <div class="title">Segments</div>
      </div>
    </div>
    <div class="segments">
      <div
        class="row segment middle-xs"
        v-for="segment in segments"
        :key="segment.id"
      >
        <div class="col-xs-1">
          <input type="checkbox" @click="toggleSelectedSegment(segment)" />
        </div>
        <div class="col-xs">
          {{ segment.short_name }}
        </div>
        <div class="col-xs-2">
          <!-- <button class="button outline" v-clipboard="segment.short_name">
            Copy
          </button> -->
        </div>
      </div>
    </div>
    <div class="row output">
      <div class="col-xs">
        {{ selectedSegmentText }}
      </div>
    </div>
    <div class="row end-xs" v-show="selectedSegmentText !== ''">
      <div class="col-xs-4">
        <button
          class="button solid"
          v-clipboard="
            () =>
              selected_segments
                .map(segment => {
                  return segment.short_name;
                })
                .join(', ')
          "
        >
          Copy Segments
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      query: "",
      size: 50,
      offset: 0,
      selected_providers: [],
      selected_segments: []
    };
  },
  watch: {
    query() {
      this.offset = 0;
      this.getSegments({
        query: this.query,
        size: this.size,
        offset: this.offset,
        provider_id: this.provider_ids
      });
    },
    size() {
      this.offset = 0;
      this.getSegments({
        query: this.query,
        size: this.size,
        offset: this.offset,
        provider_id: this.provider_ids
      });
    },
    selected_providers() {
      this.offset = 0;
      this.getSegments({
        query: this.query,
        size: this.size,
        offset: this.offset,
        provider_id: this.provider_ids
      });
    }
  },
  async mounted() {
    await this.getProviders();
  },
  beforeDestroy() {
    this.$store.commit("SET_SEGMENTS", []);
  },
  computed: {
    ...mapState({ providers: "providers", segments: "segments" }),
    provider_ids() {
      let ids = this.selected_providers.map(provider => provider.id);
      return ids.length > 0 ? ids : undefined;
    },
    selectedSegmentText() {
      let segments = this.selected_segments.map(segment => {
        return segment.short_name;
      });

      let segmentCount = segments.length;
      if (segmentCount > 3) {
        let truncSegments = segments.slice(0, 3);
        return (
          truncSegments.join(", ") + ` and ${segmentCount - 3} more segment(s)`
        );
      }

      return segments.join(", ");
    }
  },
  methods: {
    ...mapActions({ getProviders: "getProviders", getSegments: "getSegments" }),
    debounceInput(e) {
      if (e === "") {
        this.$store.commit("SET_SEGMENTS", []);
        return;
      }

      this.query = e;
    },
    prev() {
      let result = this.offset - this.size;

      this.offset = result > 0 ? result : 0;
      this.getSegments({
        query: this.query,
        size: this.size,
        offset: this.offset,
        provider_id: this.provider_id
      });
    },
    next() {
      this.offset = this.offset + this.size;
      this.getSegments({
        query: this.query,
        size: this.size,
        offset: this.offset,
        provider_id: this.provider_id
      });
    },
    toggleSelectedSegment(segment) {
      for (let i in this.selected_segments) {
        if (this.selected_segments[i]["id"] == segment.id) {
          this.selected_segments.splice(i, 1);
          return;
        }
      }

      this.selected_segments.push(segment);
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 10px 20px;
}
.segments {
  overflow-y: auto;

  height: 325px;
  margin: 2px;
  margin-top: 20px;
  &::-webkit-scrollbar {
    width: 10px;

    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
.segment {
  padding: 10px;

  border-bottom: 1px solid #eaeaea;
  &:hover {
    background-color: #eaeaea;
  }
}
h2 {
  margin-top: 15px;
}
.title {
  margin-bottom: 0;
}
.close {
  cursor: pointer;
}
.button.outline:hover {
  color: #444;
  background-color: #fdce07;
}
.output {
  margin: 25px;
}
</style>
